.section__padding {
  padding: 1rem 1rem;
}

.footer {
  background-color: #000000;
}

.sb__footer {
  display: flex;
  flex-direction: column;
}

.sb__footer-links {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 0rem;
  color: white;
}

.sb__footer-links-div {
  width: 150px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
}

.sb__footer-below {
  text-align: center;
}

.sb__footer-links-div h4 {
  color: white;
}

a {
  color: white;
  text-decoration: none;
}

.socialmedia {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.socialmedia_icon {
  width: 90%;

}

h4 {
  color: white;
  text-align: center;
}

.span {
  color: white;
  text-align: center;
  font-size: small;
}

.footer_title {
  text-align: left;
  color: white;
}

.copyright {
  color: white;
}

.reacticon {
  align-self: center;
  margin-bottom: 10px;
}

.footer_mid {
  margin-top: 1.5rem;
}

/* 
@media screen and (max-width: 900px) {

  .socialmedia {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .socialmedia_icon {
    width: 60px;
  }

  .socialmedia_icon {
    margin-top: 1rem;
    margin-left: -1rem;
    margin-bottom: 1rem;
  }

  .section__padding {
    align-items: center;
  }

  .sb__footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }

  .sb__footer {
    padding-left: 30%;
  }

  .footer_title {
    text-align: center;
  }
  .sb__footer-links-div {
    margin: 0rem;
  }
  .span {
    margin-bottom: 30px;
  }
}


 */

 @media (max-width: 479px) {

  .image-div {
    display: flex;
    justify-content: center;
  }

  .footer_title {
    text-align: center;
  }

  .license {
    max-width: 60vw;
    min-width: 40vw;
  }

  .sb__footer-links {
    flex-direction: column;
    align-items: center;
  }

  .sb__footer-links-div {
    width: 50%; 
    margin-bottom: 1rem; 
  }

  .socialmedia {
    justify-content: space-around; 
  }

  .policy-link {
    text-align: center;
  }

  .modal-content-footer {
    max-width: 70vw;
    max-height: 70vh;
    padding: 2rem;
  }

}

@media (min-width: 480px) and (max-width: 767px) {
  .image-div {
    display: flex;
    justify-content: center;
  }

  .footer_title {
    text-align: center;
  }

  .license {
    max-width: 50vw;
    min-width: 40vw;
  }

  .sb__footer-links {
    flex-direction: column;
    align-items: center;
  }

  .sb__footer-links-div {
    width: 50%; 
    margin-bottom: 1rem; 
  }

  .socialmedia {
    justify-content: space-around; 
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .image-div {
    display: flex;
    justify-content: center;
  }

  .footer_title {
    text-align: center;
  }

  .license {
    max-width: 60vw;
    min-width: 40vw;
  }

  .sb__footer-links {
    flex-direction: column;
    align-items: center;
  }

  .sb__footer-links-div {
    width: 50%; 
    margin-bottom: 1rem; 
  }

  .socialmedia {
    justify-content: space-around; 
  }

}
