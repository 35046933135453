.navbar {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    top: 0;
    position: sticky;
    z-index: 1000;
  }
  
  .logo img {
    width: 60px;
    max-height: 50px;
  }
  
  .menu-toggle {
    cursor: pointer;
    display: none;
  }
  
  .menu-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menu-items li {
    margin: 0 10px;
  }
  
  .menu-items li a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .menu-items li a:hover {
    color: #76a6d8;
  }
  
  /* Mobile view */
  @media (max-width: 768px) {
    .logo img {
      width: 40px;
    }

    .menu-toggle {
      display: block;
      color: rgb(0, 0, 0);
    }
  
    .menu-items {
      display: flex;
      flex-direction: column;
      background-color: white;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      z-index: 1000;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
    }
  
    .menu-items.open {
      max-height: 600px;
      opacity: 1;
    }
  
    .menu-items li a {
      color: rgb(0, 0, 0);
    }
  
    .menu-items li {
      margin: 10px 0;
      text-align: center;
    }
  }
  